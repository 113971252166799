import React, { useState, useEffect, useCallback, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";
import { BASE_URL } from "../../VariableHandler/handler";
import { GlobalContext } from "../Context/GlobalContext";



export default function ServiceView(){
    const { slug }  = useParams();

    const { serviceData, loader } = useContext(GlobalContext); // Access the service data from GlobalContext

    const [serviceViewData, setServiceViewData] = useState(null);

    useEffect(() => {
        // Find the specific service by slug from the global context data
        if (serviceData) {
            const service = serviceData.find(s => s.slug === slug);
            setServiceViewData(service);
        }
    }, [serviceData, slug]);

    if (loader || !serviceViewData) {
        return <Skeleton />;
    }

    if (!serviceViewData) {
        console.error('Service Data not found!');
        return <div>Service Data not found!</div>; // Handle case where service data is not found
    }
    
    return(

        <React.Fragment>
            <Helmet>
                <title>Prosecure | {serviceViewData.title}</title>
                <meta name="description" content={`Western Safety Patrol offer best ${serviceViewData.title} service.`}/>
            </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('/frontend/assets/image/Alphafire.jpg')"}}>
            {
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">{serviceViewData ? serviceViewData.title : 'Service'}</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/service'} className="breadcrumb-item">Service</NavLink>
                            <NavLink to={'about'} className="breadcrumb-item active">{serviceViewData ? serviceViewData.title : 'Service'}</NavLink>
                        </div>
                    </Container>
                </div>
            }
            </div>
            <section className='py-5' style={{overflow: "auto"}}>
                <Container fluid>
                {
                    serviceViewData ? (
                        <Col md="12" lg="12">
                            <div className="text-wrapper">
                            {/* {textDescription(description.description)} */}
                                <div className="certificate-image mx-2">
                                    <img src={`${BASE_URL}/uploads/service/${serviceViewData.image}`} className="img-thumbnail" alt="certificate"/>
                                </div>
                                <h4 className='service-title'>{serviceViewData.title}</h4>
                                    <Description dangerouslySetInnerHTML={{ __html: serviceViewData.description }} />
                            </div>
                        </Col>
                    ) : 
                    (
                        <React.Fragment></React.Fragment>
                    )
                }
                </Container>
            </section>
        </React.Fragment>
    )
}

const Description = styled.div`
    margin-top: 1em;
`;
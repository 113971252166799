import React, { useState, useEffect, useContext } from "react";
import Header from "./Components/Include/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./Components/Include/Footer";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import { GlobalContext, GlobalProvider } from "./Components/Context/GlobalContext";
import MobileAppScreen from "./Components/Elements/MobileScreen";
import Loader from "./Components/Elements/Loader";
import Service from "./Components/Pages/Service";
import ServiceView from "./Components/Pages/ServiceView";
import Career from "./Components/Pages/Career";
import { ThemeProvider } from "styled-components";
import { theme } from "./VariableHandler/handler";
import CareerView from "./Components/Pages/CareerView";
import Contact from "./Components/Pages/Contact";
import ApplicantForm from "./Components/Pages/ApplicantForm";

function App() {
  return (
    <GlobalProvider>
      <MainContent />            
    </GlobalProvider>
  );
}

export default App;

// Component to handle loader and error states
function MainContent() {
  const { loader } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  // Set custom delay time in milliseconds
  const customDelay = 3000; // 3000ms = 3 seconds

  useEffect(() => {
      // If loader is true, start a timer
      if (loader) {
          const timer = setTimeout(() => {
              setLoading(false);
          }, customDelay);

          // Cleanup timer on unmount or when loader changes
          return () => clearTimeout(timer);
      } else {
          setLoading(false);
      }
  }, [loader, customDelay]);

  if (loader) {
      return <Loader />;
  }

  return (
          <ThemeProvider theme={theme}>
              <BrowserRouter>
                  <Header/>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="about" element={<About />} />
                      <Route path="service" element={<Service />} />
                      <Route path="serviceview/:slug" element={<ServiceView />} />
                      <Route path="career" element={<Career />} />
                      <Route path='careerview/:slug' Component={<CareerView/>}/>
                      <Route path='careerform/applicant' Component={<ApplicantForm/>}/>
                      <Route path="mobileapp" element={<MobileAppScreen />} />
                      <Route path="contact" element={<Contact />} />
                  </Routes>
                  <Footer/>
              </BrowserRouter>
          </ThemeProvider>
  );
};

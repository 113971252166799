import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { BASE_URL } from "../../VariableHandler/handler";
import { GlobalContext } from "../Context/GlobalContext";
import { Skeleton } from "@mui/material";
import Aos from "aos";

export default function Video(){

    const { videoData, loader } = useContext(GlobalContext);
    const [play, setPlay] = useState(false);
    const videoRef = useRef(null);
    const sectionRef = useRef(null);

    useEffect(() => {

        
        if (videoRef.current) {
            const video = videoRef.current;
            
            const handleVideoEnd = () => {
                setPlay(false);
            };
            
            video.addEventListener('ended', handleVideoEnd);
            
            return () => {
                video.removeEventListener('ended', handleVideoEnd);
            };
        }
        Aos.init();
    }, [videoData]);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    videoRef.current?.play();
                } else {
                    videoRef.current?.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5 // Adjust this value based on when you want the video to play/pause
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    function handlePlay() {
        if (play) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setPlay(!play);
    };

    // console.log("Video", videoData);

    if(loader){
        <Row>
            <Col lg="12">
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton width="100%"/>
                <Skeleton/>
            </Col>
           
        </Row>
    };
    return(
        <Section ref={sectionRef} className="position-relative" data-aos="fade-up" data-aos-duration="1500">
            {
                videoData && (
                    <>
                        <VideoClip ref={videoRef} src={`${BASE_URL}/uploads/videos/${videoData.video_file}`} ></VideoClip>
                        <PlayButton onClick={handlePlay}><i className={`fa-solid fa-${play ? 'pause' : 'play'}`}></i></PlayButton>
                    </>
                )
            }
        </Section>
    )
}



const VideoClip = styled.video`
    width: 100%;
    height: auto;
`;

const Section = styled.section`

    &::after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.6); 
    }
`;

const PlayButton = styled.button`
    position: absolute;
    top: 40%;
    left: 48%;
    width: 60px; /* Adjust size to your preference */
    height: 60px; /* Adjust size to your preference */
    font-size: 24px; /* Adjust icon size */
    color: #fff;
    border: none;
    border-radius: 50%; /* Make the button round */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;
    transition: background 0.3s ease;

    &:hover {
        background: rgba(0, 0, 0, 0.7);
    }

    &:active {
        background: rgba(0, 0, 0, 0.9);
    }

    i {
        pointer-events: none; /* Prevents pointer events on the icon */
    }
`;
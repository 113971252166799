import React, { useContext} from "react";
import styled from "styled-components";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalContext";
import { BASE_URL } from "../../VariableHandler/handler";

export default function Header(){

    const { profile, serviceData, whatsappNumber, apiDataAvailable } = useContext(GlobalContext);
    

    const redirectToWhatsapp = () => {
        const whatsBASE_URL = `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsappNumber)}`;
        window.open(whatsBASE_URL, '_blank');
      
    };

    const handleNavSelect = () => {
        // This function will be called when a Nav.Link is selected
        // Close the Navbar toggle by setting the state to false
        document.getElementById("basic-navbar-nav").classList.remove("show");
        // console.log('remove show class');
    };

    return(
        <Height>
            <Navbar expand="lg" className="bg-body-tertiary fixed-top bg-white navbar-light">
                <Container fluid>
                    <Navbar.Brand href="/">
                        {apiDataAvailable && profile.company_logo ? (
                            <img src={`${BASE_URL}/uploads/profile/${profile.company_logo}`} alt="logo" className="logo"/>
                        ) : (
                            <img src="frontend/assets/image/prosecure-logo.png" alt="logo" className="logo"/>
                        )}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-dark"><i className="fa-solid fa-bars"></i></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" onClick={handleNavSelect}>
                        <Nav className="me-auto">
                            <NavLink to="/" className="nav-link">Home</NavLink>
                            <NavLink to="about" className="nav-link">About us</NavLink>
                            <NavLink to={'/service'} className="nav-link hover-dropdown service-parent">
                                Service
                                <ul className="drop-list">
                                    {apiDataAvailable && serviceData.map((data, index) => (
                                        <li key={`head-service-list-${index}`}>
                                            <NavLink to={`/serviceview/${data.slug}`} className="navlink service-child">{data.title}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </NavLink>
                            <NavLink to="career" className="nav-link">Career</NavLink>
                            <NavLink to="contact" className="nav-link">Contact us</NavLink>
                            {/* <div className="nav-link" onClick={redirectToWhatsapp}><i className="fa-brands fa-whatsapp"></i></div> */}
                            <NavLink to="/mobileapp" className="nav-link">Download</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Height>
    )
}

const Height = styled.div`
    height: 91px;

    @media screen and (max-width: 1190px){
        height: 56px;
    }

`
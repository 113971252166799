import React from "react";
import { Helmet } from "react-helmet";
import HomeBanner from "../Sections/HomeBanner";
import ServiceCategory from "../Sections/ServiceCategory";
import HomeAbout from "../Sections/HomeAbout";
import BestService from "../Sections/BestService";
import ServiceCarousel from "../Sections/ServiceCarousel";
import Video from "../Sections/VideoSection";
import CommonSection from "../Sections/CommonSection";
import Client from "../Sections/Client";
import Director from "../Sections/Director";


export default function Home(){

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content="Prosecure"/>
                <title>Prosecure | Home</title>
            </Helmet>
            <HomeBanner/>   
            <ServiceCategory/>
            <HomeAbout/>
            <BestService/>
            <Video/>
            <ServiceCarousel/>
            <CommonSection/>
            <Client/>
            <Director/>
        </React.Fragment>
        
    )
}


import React from "react";
import styled, { keyframes } from "styled-components";
import "../Style/Loader.css";




export default function Loader(){
  

  return(
    <LoaderContainer>
              Loading...
          <div className="loader"></div>
    </LoaderContainer>
  )
};




const r = "2.5em"; // outer radius of ring
const b = `calc(0.2 * ${r})`; // ring thickness
const u = `calc(0.5 * ${b})`;
const t = "6s";
const e = "cubic-bezier(0.365, 0, 0.635, 1)";

const p = keyframes`
  50%, 100% {
    --p: 100;
  }
`;

const rAnimation = keyframes`
  0%, 50% {
    --r: calc(100% - ${b});
  }
  75%, 100% {
    --r: -1px;
  }
`;

const x = keyframes`
  100% {
    --x: calc(100% - ${u});
  }
`;

const y = keyframes`
  100% {
    --y: calc(100% - ${u});
  }
`;

const LoaderContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  background: #171717;
  font: 400 6em 'Cousine', monospace;


`;

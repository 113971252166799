import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import 'aos/dist/aos.css';
import { Skeleton } from "@mui/material";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GlobalContext } from "../Context/GlobalContext";
import { serviceArray } from "../../VariableHandler/handler";
import { BASE_URL } from "../../VariableHandler/handler";


export default function ServiceCarousel(){

    const { loader, serviceData, apiDataAvailable } = useContext(GlobalContext);

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 2, // Show 2 cards at 500px and up
        },
        1000: {
            items: 3, // Show 3 cards at 1000px and up
        },
    };

    useEffect(() => {
        Aos.init();
    },[]);

    if(loader){
        <Row>
            <Col lg="4">
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton width="40%"/>
                <Skeleton/>
            </Col>
            <Col lg="4">
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton width="40%"/>
                <Skeleton/>
            </Col>
            <Col lg="4">
                <Skeleton variant="rectangular" height={300}/>
                <Skeleton width="40%"/>
                <Skeleton/>
            </Col>
        </Row>
    }

    return (
        <section className="py-5"> 
            <Container fluid>
                <div data-aos="fade-up" data-aos-duration="1500">
                    <h2 className="text-center section-title my-5">Our Services</h2>
                    <div className="text-center" style={{fontSize:"20px",marginBottom:"80px"}}>
                        <p>We specialize in the installation and integration of CCTV, intrusion alarm, and fire alarm systems, all connected to a centralized command center to effectively manage emergencies and ensure comprehensive safety for our clients. Our services include complete surveillance system installation, provision of comprehensive fire safety equipment for industrial use, and offering annual maintenance contract (AMC) services after the initial installation to ensure ongoing reliability and protection.</p>
                    </div>                      
                </div>
                <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={true} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                {
                    apiDataAvailable && ServiceCarousel ? (
                        serviceData.map((card,index) => (        
                            <div key={`carouselcard-${index}${card.id}`} className="frame"  data-aos="zoom-in" data-aos-duration="1500">
                                <img src={`${BASE_URL}/uploads/service/${card.image}`} className="service-image" alt="carouselimage"/>
                                <div className="text-wrapper">
                                    <h4>{card.title}</h4>
                                    <p>
                                        {card.content}
                                    </p> 
                                    <NavLink to={`serviceview/${card.slug}`} className="text-danger redirect-link">Learn more <i className="fa-solid fa-arrow-right"></i></NavLink>
                                </div>
                            </div>    
                        ))
                    ) : 
                    (
                        serviceArray.map((card,index) => (        
                            <div key={`carouselcard-${index}${card.id}`} className="frame"  data-aos="zoom-in" data-aos-duration="1500">
                                <img src={`./assets/image/${card.src}`} alt="carouselimage"/>
                                <div className="text-wrapper">
                                    <h4>{card.title}</h4>
                                    <p>
                                        {card.content}
                                    </p> 
                                    <NavLink to={'about'} className="text-danger redirect-link" target="_blank">Learn more <i className="fa-solid fa-arrow-right"></i></NavLink>
                                </div>
                            </div>    
                        ))
                    )
                }
                </ReactOwlCarousel>
            </Container>
        </section>
    )
}
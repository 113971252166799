import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import { GlobalContext } from "../Context/GlobalContext";
import { BASE_URL } from "../../VariableHandler/handler";

export default function Certificate(){
    
    const {certificate, apiDataAvailable } = useContext(GlobalContext);

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 2, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };

    return (
        <section className="my-3">
            <Container fluid>
                <ReactOwlCarousel className="owl-theme" margin={20} responsive={responsiveOptions} autoplayHoverPause={true} nav={true} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                    {
                        certificate.map((cert, index) => (
                            <div className="certificate-item" key={index}>
                                <div className="certificate-image">
                                    <NavLink to={`${BASE_URL}/uploads/certificate/${cert.certificate_image}`} target="_blank">
                                        <img src={`${BASE_URL}/uploads/certificate/${cert.certificate_image}`} className="img-thumbnail certificate" alt="certificate" />
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </ReactOwlCarousel>
            </Container>
        </section>
    )
}
import React, { useState, useEffect } from 'react';



const TopArrowButton = () => {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
        // Adjust the scrollY value as needed to control when the button appears
        setShowButton(true);
        } else {
        setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button
        className={`top-arrow-button ${showButton ? 'show' : ''}`}
        onClick={scrollToTop} shadow="true"><i className="fa-solid fa-chevron-up"></i></button>
    );
};

export default TopArrowButton;

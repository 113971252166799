import React, { useContext } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { Skeleton } from "@mui/material";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { testimonial } from "../../VariableHandler/handler";
import { GlobalContext } from "../Context/GlobalContext";
import { BASE_URL } from "../../VariableHandler/handler";


export default function Testimonial(){
    
    const {testimonialData, loader, apiDataAvailable } = useContext(GlobalContext);

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };  


    // console.log({testimonialData});

    if(loader){
        return <React.Fragment>
            <Skeleton width="100%" height={100} style={{backgroundColor: 'grey'}}/>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton variant="circular" className="text-center" width={100} height={100} style={{backgroundColor: 'grey'}}/>
                <Skeleton width={200} height={30} style={{backgroundColor: 'grey'}}/>
                <Skeleton width={250} height={50} style={{backgroundColor: 'grey'}}/>
            </div>
        </React.Fragment>
    };

    return(
        <ReactOwlCarousel className="owl-theme" margin={10} nav responsive={responsiveOptions} dots={false} autoPlay={true} autoplaySpeed={2000} loop={true}>
            {
                apiDataAvailable && testimonialData ? (
                    testimonialData.map((card, index) => (
                        <div key={`testimonial-${index}`} className="testi-box">
                            <p className="review">{ `${card.message}` }</p>
                            <div className="profile-img">
                                <img src={`${BASE_URL}/uploads/testimonial/${card.image}`} className="asd" alt="profileimage"/>
                            </div>
                            <div className="text-center">
                                <p className="name">{card.name}</p>
                                <p className="designation">{card.designation}-{card.company_name}</p>
                            </div>
                        </div>
                    ))
                ) : 
                (   
                    testimonial.map((card) => (
                        <div key={`testimonial-${card.id}`} className="testi-box">
                            <p className="review">{ `“${card.review}”` }</p>
                            <div className="profile-img">
                                <img src={`frontend/assets/image/${card.src}`} className="asd" alt="profileimage"/>
                            </div>
                            <div className="text-center">
                                <p className="name">{card.name}</p>
                                <p className="designation">{card.designation}-{card.company}</p>
                            </div>
                        </div>
                    ))
                )
            }
        </ReactOwlCarousel>
    )
}
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Testimonial from "../Elements/Testimonial";
import ContactForm from "../Elements/ContactForm";
import Aos from "aos";


export default function CommonSection(){


    useEffect(() => {
        Aos.init();
    },[]);

    return (
        <section id="sectionBackground" className="bg-section py-4" style={{backgroundImage: "url('frontend/assets/image/fire-safety-bg.jpg')", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}  data-aos="fade-up" data-aos-duration="1500">
            <Container fluid>
                <Row style={{position: 'relative', zIndex: '99'}}>
                    <Col md="6" lg="5" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                        <h2 className="text-center section-title my-5">Testimonial</h2>
                        <div className="testimonial-container">
                            <Testimonial/>
                        </div>
                    </Col>
                    <Col md="0" lg="2" className="column-hide"></Col>
                    <Col md="6" lg="5" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                        <div className="title-container">
                            <h2 className="text-center section-title mt-5">Request Info</h2>
                            <p className="">Feel free to contact us for any query </p>
                        </div>
                        <div className="contact-form">
                            <ContactForm/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
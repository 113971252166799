import React, { useContext, useState, useEffect, createContext } from "react";
import { BASE_URL } from "../../VariableHandler/handler";
import Swal from "sweetalert2";

// Create a context
export const GlobalContext = createContext();


export const GlobalProvider = ({ children }) => {

    

    const [loader, setLoader] = useState(true);
    const [apiDataAvailable, setApiDataAvailable] = useState(true);
    const [profile, setProfile] = useState([]);
    const [serviceCategory, setServiceCategory] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [whatsappNumber, setWhatsappNumber] = useState('9254029400'); // default number
    const [counter, setCounter] = useState(null);
    const [homepage, setHomepage] = useState(null);
    const [bestHeading, setBestHeading] = useState(null);
    const [aboutContent, setAboutContent] = useState(null);
    const [bestService, setBestService] = useState([]);
    const [addressData, setAddressData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [testimonialData, setTestimonialData] = useState([]);
    const [videoData, setVideoData] = useState(null);
    const [teamData, setTeamData] = useState([]);
    const [description, setDescription] = useState('');
    const [imageSlider, setImageSlider] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    const [career, setCareer] = useState(null);
    

    const fetchData = async () => {
        setLoader(true);
        
        try {
            const [
                profileResponse, 
                clientResponse,
                serviceCategoryResponse,
                serviceDataResponse, 
                whatsappResponse,
                counterResponse, 
                homepageResponse, 
                bestServiceResponse, 
                bestHeadingResponse, 
                aboutContentResponse,
                addressResponse, 
                contactResponse,
                testimonialResponse,
                videoResponse,
                teamResponse,
                descriptionResponse,
                imageSliderResponse,
                certificateResponse,
                galleryResponse,
                careerResponse,
                
                ] = await Promise.all([
                fetch(`${BASE_URL}/api/profile`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/client`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/servicecategory`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/service`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/whatsapp`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/counterapi`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/homepage`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/bestService`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/heading`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/footercontent`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/address`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/contact`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/testimonial`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/video`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/team`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/description`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/imageslider`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/certificate`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/service`).then((response) => response.json()),
                fetch(`${BASE_URL}/api/career`).then((response) => response.json()),
            ]);
            setProfile(profileResponse);
            setClientData(clientResponse);
            setServiceCategory(serviceCategoryResponse);
            setServiceData(serviceDataResponse);
            setWhatsappNumber(whatsappResponse.whatsapp_number || '9254029400');
            setCounter(counterResponse);
            setHomepage(homepageResponse);
            setBestService(bestServiceResponse);
            setBestHeading(bestHeadingResponse);
            setAboutContent(aboutContentResponse);
            setAddressData(addressResponse);
            setContactData(contactResponse);
            setTestimonialData(testimonialResponse);
            setVideoData(videoResponse);
            setTeamData(teamResponse);
            setDescription(descriptionResponse);
            setImageSlider(imageSliderResponse);
            setCertificate(certificateResponse);
            setGalleryData(galleryResponse);
            setCareer(careerResponse);
            
        } catch (error) {
            console.error('Error fetching data', error);
            setApiDataAvailable(false); // API data is not available
            Swal.fire('Error', 'Error fetching data', 'error');
            setLoader(true);
        }finally{
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <GlobalContext.Provider value={{ 
                profile, 
                clientData,
                serviceCategory,
                serviceData, 
                whatsappNumber, 
                apiDataAvailable, 
                counter, 
                homepage, 
                bestService, 
                bestHeading, 
                aboutContent, 
                addressData,
                contactData,
                loader,
                videoData,
                testimonialData,
                teamData,
                description,
                imageSlider,
                certificate,
                galleryData,
                career
            }}>
            {children}
        </GlobalContext.Provider>
    )
}
import React, { useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalContext";
import { Skeleton } from "@mui/material";
import Aos from "aos";
import { Helmet } from "react-helmet";
import '../Style/about.css';
import AboutDescription from "../Sections/AboutDescription";
import CounterSection from "../Sections/CounterSection";
import Certificate from "../Sections/Certificate";
import Director from "../Sections/Director";

export default function About(){

    const { loader } = useContext(GlobalContext);

     // OwlCarousel Responsive 
     const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };

    useEffect(() => {
        Aos.init();
    }, []);

    if(loader){
        return  <Row>
                    <Col lg="4">
                        <Skeleton variant="rectangular" height={300}/>
                        <Skeleton width="40%"/>
                        <Skeleton/>
                    </Col>
                    <Col lg="4">
                        <Skeleton variant="rectangular" height={300}/>
                        <Skeleton width="40%"/>
                        <Skeleton/>
                    </Col>
                    <Col lg="4">
                        <Skeleton variant="rectangular" height={300}/>
                        <Skeleton width="40%"/>
                        <Skeleton/>
                    </Col>
                </Row>

    }

    return(
        <React.Fragment>
            <Helmet>
                <title>Prosecure | About</title>
                <meta name="description" content="WSP provides a full suite of private contract security and patrol services with a customer-focused culture that is unique to the industry. We are able to tailor our offerings to clients’ needs due in-part to our well-trained personnel with varied backgrounds that include law enforcement, private security, customer service, and military experience."/>
            </Helmet>
            <AboutDescription/>
            <CounterSection/>
            <Certificate/>
            <Director/>
        </React.Fragment>

    )
}
import React , {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";



export default function Searchbar({searchProps}){

    return (
        <section className="career-section">
            <Container>
                <SearchContainer>
                    <SearchBox className="set-width">
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input type="text" onChange={(e) => searchProps(e.target.value)} placeholder='search by title, location, type...'/>
                    </SearchBox>
                </SearchContainer>
            </Container>
        </section>
    )
}


const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 3em 0;

  @media (max-width:${({ theme }) => theme.media.mobile}){
    flex-direction: column;
  }
`;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  align-items:center;
  border-radius: 7px;
  border: 1px solid #CEC9C9;
  padding: 19px 30px;

  i{
    font-size:25px;
    margin-right:1em;
  }
  
  input{
    border:none;
    font-size: 18px;
  }
  input[type=text] {
    width: 100%;
    box-sizing: border-box;
    color: #000;
    // font-size: 18px;
  }
  input:focus{
    outline-width: 0;
  }
  input::placeholder{
    // color: #000;
    // font-weight: 600;
  }

  @media (max-width:${({ theme }) => theme.media.tab}) {
    width: 25%;    
    padding: 16px;  
    
    i{
      margin-right: 10px;
      font-size: 17px;
    }
    input{
      font-size: 14px;
    }
  }

  @media (max-width:${({ theme }) => theme.media.mobile}) {
    width: 100%;    
    padding: 16px;  
    border-radius: 7px 7px 0px 0px;
    i{
      margin-right: 10px;
      font-size: 17px;
    }
    input{
      font-size: 14px;
    }
  }
`;

const Location = styled.div`
  width: 27%;
  display: flex;
  align-items:center;
  border-radius: 0px 0px 0px 0;
  border: 1px solid #CEC9C9;
  padding: 19px 30px;
  font-size: 18px;

  i{
    font-size:25px;
    margin-right:1em;
  }

  @media (max-width:${({ theme }) => theme.media.tab}) {
    width: 25%;    
    padding: 16px; 
    font-size: 14px;

    i{
      margin-right: 10px;
      font-size: 17px;
    }
  }
  @media (max-width:${({ theme }) => theme.media.mobile}) {
    width: 100%;    
    padding: 16px;  
    
    i{
      margin-right: 10px;
      font-size: 17px;
    }
    input{
      font-size: 14px;
    }
  }
`;

const SearchType = styled.div`
  width: 27%;
  display: flex;
  align-items:center;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #CEC9C9;
  padding: 19px 30px;
  font-size: 18px;

  i{
    font-size:25px;
    margin-right:1em;
  }

  @media (max-width:${({ theme }) => theme.media.tab}) {
    width: 25%;    
    padding: 16px;  
    font-size: 14px;

    i{
      margin-right: 10px;
      font-size: 17px;
    }
  }
  @media (max-width:${({ theme }) => theme.media.mobile}) {
    width: 100%;    
    padding: 10px;  
    font-size: 12px;

    i{
      margin-right: 10px;
      font-size: 15px;
    }
  }
`;

const SearchButton = styled.button`
  width: 15%;
  background-color: #454E9F;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.75em;
  cursor: pointer;
  border-radius: 0px 7px 7px 0px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1d267c;
  }

  @media (max-width:${({ theme }) => theme.media.tab}) {
    width: 12%;    
    padding: 16px;  
    font-size: 17px;
  }

  @media (max-width:${({ theme }) => theme.media.mobile}) {
    width: 100%;    
    padding: 16px;  
    font-size: 17px;
    border-radius: 0px 0px 19px 19px;
  }
`;

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import '../Style/Contact.css';
import { BASE_URL } from "../../VariableHandler/handler";
import Swal from "sweetalert2";


export default function ContactForm(){

    

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let newValue = value;

        if (name === 'mobile') {
            newValue = value.replace(/\D/g, '').slice(0, 10);
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (formData.first_name.trim() === '') {
            newErrors.first_name = 'First Name is required';
            valid = false;
        }
        if (formData.last_name.trim() === '') {
            newErrors.last_name = 'Last Name is required';
            valid = false;
        }
        if (formData.email.trim() === '' || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            valid = false;
        }
        if (formData.mobile.trim() === '' || !/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number is required';
            valid = false;
        }
        if (formData.message.trim() === '') {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                const response = await fetch(`${BASE_URL}/api/contact-post`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                

                if (response.ok) {
                    Swal.fire('Success', 'Form submitted successfully!', 'success');
                    setFormData({
                        first_name: '',
                        last_name: '',
                        email: '',
                        mobile: '',
                        message: '',
                    });
                } else {
                    const errorData = await response.json();
                    console.error('Error data:', errorData);
                    Swal.fire('Error', 'Form submission failed: ' + errorData.message, 'error');
                }
            } catch (error) {
                console.error('Network error:', error);
                Swal.fire('Error', 'Form submission failed due to a network error. Please try again later.', 'error');
            }
        }
    };

    

    function onChange(value) {
        console.log("Captcha value:", value);
    }


    return (
        <Form onSubmit={handleSubmit}> 
            <Row className="responsive-reverse">
            <Col sm={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleFName.Controlinput1">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleInputChange} placeholder="First Name"/>
                        <div className="text-danger">{errors.first_name}</div>
                    </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleLName.Controlinput1">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleInputChange} placeholder="Last Name"/>
                        <div className="text-danger">{errors.last_name}</div>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.Controlinput2">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="name@example.com"/>
                <div className="text-danger">{errors.email}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.Controlinput3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" name="mobile" value={formData.mobile} onChange={handleInputChange} placeholder="10 digit mobile number"/>
                <div className="text-danger">{errors.mobile}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.Controlinput4">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" value={formData.message} onChange={handleInputChange} placeholder="Your message here..."/>
                <div className="text-danger">{errors.message}</div>
            </Form.Group>

            <div className="">
                <ReCAPTCHA sitekey="6LfB6R0qAAAAABbE3YUpWcvTLQUm6PQhq38qoE8u"
                    onChange={onChange}
                />
            </div>
            <button type="submit" className="eva-btn eva-outline-white my-3 contact-btn">Send me quote</button>
        </Form>
    )
}
import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import { Skeleton } from "@mui/material";
import { serviceCard } from "../../VariableHandler/handler";
import { GlobalContext } from "../Context/GlobalContext";

export default function BestService(){  

    const { apiDataAvailable,  bestService, bestHeading, loader } = useContext(GlobalContext);

    useEffect(() => {
        Aos.init();
    },[]);

    if(loader){
        return <React.Fragment>
            <Container>
                
                <Skeleton variant="rectangular" height={40} sx={{margin: "84px 0 0 0"}}/>

                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={100} height={100} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rectangular" height={60} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rounded" height={60} sx={{margin: "1em 0"}}/>
            </Container>
        </React.Fragment>
    }

    return(
        <section className="py-5" style={{backgroundColor: "#f8f9fa"}}>
            <Container fluid>
                <h2 className="text-center section-title"  data-aos="fade-up" data-aos-duration="1500">{apiDataAvailable && bestHeading ? bestHeading.heading : 'We Provide The Best Services'}</h2>
                <Row>
                    {
                        apiDataAvailable && bestService ? (
                            bestService.map((service) => (
                                <Col key={service.id} sm="12" md="4" lg="4">
                                    <div className="container-wrapper"  data-aos="fade-up" data-aos-duration="1500">
                                        <div className="text-center icon">
                                            <div dangerouslySetInnerHTML={{__html: service.icon}}/>
                                        </div>
                                        <div className="label">
                                            <h4 className="label-content">{service.title}</h4>
                                            <p className="label-paragraph">
                                                {service.content}
                                            </p>
                                            {/* <NavLink to={'about'} className="eva-btn eva-btn-jasper text-uppercase">learn more..</NavLink> */}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        ) :
                        (
                            serviceCard.map((card, index) => (
                                <Col key={index} sm="12" md="4" lg="4">
                                    <div className="container-wrapper"  data-aos="fade-up" data-aos-duration="1500">
                                        <div className="text-center icon">
                                            <i className={card.icon}></i>
                                        </div>
                                        <div className="label">
                                            <h4 className="label-content">{card.title}</h4>
                                            <p className="label-paragraph">
                                                {card.description}
                                            </p>
                                            {/* <NavLink to={'about'} className="eva-btn eva-btn-jasper text-uppercase">learn more..</NavLink> */}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        )
                    }
                </Row>
            </Container>
        </section>
    )
}
import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalContext";
import ReactOwlCarousel from "react-owl-carousel";
import '../Style/about.css';
import { BASE_URL } from "../../VariableHandler/handler";


export default function AboutDescription(){

    const { apiDataAvailable, description, imageSlider } = useContext(GlobalContext);

    // OwlCarousel Responsive 
    const responsiveOptions = {
       0: {
           items: 1, // Show 1 card at 0px and up
       },
       500: {
           items: 1, // Show 2 cards at 500px and up
       },
       1000: {
           items: 1, // Show 3 cards at 1000px and up
       },
   };


   return (
    <React.Fragment>
        <div className="bg-image" style={{backgroundImage: "URL('frontend/assets/image/firefighters-training-live-fire.jpg')"}}>
            <div className="py-3 position-relative" style={{zIndex: "999"}}>
                <Container fluid> 
                    <h1 className="page-name">About</h1>
                    <div className="breadcrumb">
                        <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                        <NavLink to={''} className="breadcrumb-item active"> About</NavLink>
                    </div>
                </Container>
            </div>
        </div>
        <section className="aboutpage-section" data-aos="fade-up" data-aos-duration="1500">
            <Container fluid className="py-5">
                <Row>
                    <Col md="12" lg="12">
                        {
                            <div className="text-wrapper">
                                <div className="certificate-image mx-2" data-aos="flip-right" data-aos-duration="1500">
                                <ReactOwlCarousel className="owl-theme homepage-image" responsive={responsiveOptions} margin={10} autoplayHoverPause={true} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                                    {
                                        apiDataAvailable && imageSlider.map((data, index) => (
                                            <img key={`image-${index}${data.id}`} src={`${BASE_URL}/uploads/aboutimage/${data.image}`} className="img-thumbnail slider" alt="certificate"/>
                                        ))
                                    }
                                </ReactOwlCarousel>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: description.description }} />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

        </section>
    </React.Fragment>
   )
}


export const BASE_URL = "https://admin.prosecure.co.in";


// media query theme 
export const theme = {
    colors: {
      primary_color: '#D43647',
      hover_primary_color: '#f7021b',
      main_color: '#454E9F',
    },
    fontFamily: {
      heading: 'Montserrat',
      paragraph: 'Inter',
      highlight: 'Montserrat',
    },
    media: {
      tab: '998px',
      mobile: '767px',
      sm_mobile: '576px',
      xsm_mobile: '372px',
    }
};

// Client Logo 
export const ClientData = [
    {
        id: 1,
        title: 'Crimsoune Club',
        image: 'crimsoune-club-logo.jpeg',
    },
    {
        id: 2,
        title: 'GM Foods',
        image: 'gm-food-logo.jpg',
    },
    {
        id: 3,
        title: 'K K Lights',
        image: 'kk-lights-logo.jpeg',
    },
    {
        id: 4,
        title: 'Natraj Stationary',
        image: 'natraj-logo.png',
    }
];

export const serviceCard = [
    {
        id: '1',
        title: 'perfect anytime',
        icon: "fa-solid fa-clock",
        description:
        'Our rapid response team action time is less than five to seven minutes to reach at defined service areas to take required action.',
    },
    {
        id: '2',
        title: '24/7 communication',
        icon: "fa-solid fa-headset",
        description:
          `We are 24*7 hours available on our helplines to know our client's emergencies and give them best support.`,
    },
    {
        id: '3',
        title: 'our fleet',
        icon: "fa-solid fa-car",
        description:
          `Rapid Response Team, Patrolling Officers, Ex- Military Trainers, Tech- Experts, Surveillance Experts, Detectives.`,
    },
    {
        id: '4',
        title: 'Advance Technology',
        icon: "fa-solid fa-laptop",
        description:
          `We use State -of-art Technology, Officer scans QR-code placed on locations, the information is sent to the Web application via a cloud server including the Geo-Location in real time and client is also update via Whats app or E-mail.`,
    },
    {
        id: '5',
        title: 'Trained Officer',
        icon: "fa-solid fa-user-tie",
        description:
          `Armed, unarmed, law enforcement security – we have the right guards for the job. We also provide specialty staffing in such areas as electronic surveillance, control room operations, retail and mall service, cash transaction escorts, and more.`,
    },
    {
        id: '6',
        title: 'Emergency Help',
        icon: "fa-solid fa-fire-extinguisher",
        description:
          `We work round the clock for our client's satisfaction and employee care. Our trained staff is well capable to support in emergency situations whether Medical / Fire.`,
    },  
];



export const CategoryCard = [
    {
        id: 1,
        icon: "fa-solid fa-industry",
        title: "Industrial Security",
        list: [
            "Commercial Entities",
            "Hotels",
            "Industrial Sites",
            "Logistics & Warehouse",
            "parking Lots",
            "Trucks yards"
        ]
    },
    {
        id: 2,
        icon: "fa-solid fa-building",
        title: "Commercial Security",
        list: [
            "Commercial Sites",
            "corporate building",
            "mall & plazas",
            "Logistics & Warehouse",
            "parking Lots",
            "Trucks yards"
        ]
    },
    {
        id: 3,
        icon: "fa-solid fa-warehouse",
        title: "Residential Security",
        list: [
            "apartment building",
            "alarm response",
            "condominiums",
            "mobile patrol",
            "Residential complexes",
            "retails"
        ]
    },
];

export const serviceArray = [
    {
        id: 1,
        src: "rectangle-25.png",
        title: "Transportation",
        content: "We provide secure transportation for executives, diplomats, VIPs, healthcare patients, facility guests, and others.",
    },
    {
        id: 2,
        src: "rectangle-26.png",
        title: "vehicle patrol",
        content: "Mobile patrol is an effective and often lower-cost way to secure one or many sites.",
    },
    {
        id: 3,
        src: "rectangle-27.png",
        title: "alarm & incident response",
        content: "Personnel response solutions for alarms, incidents, and emergencies (Fire, Medical etc)",
    },
    {
        id: 4,
        src: "event-security1.jpg",
        title: "event security",
        content: "Our event security expertise includes trade shows, Fortune 500 Companies, medium-sized businesses, and local events.",
    },
    {
        id: 5,
        src: "cctv-camera.jpg",
        title: "security camera",
        content: "We offer cutting-edge security camera services to protect your property and ensure peace of mind.",
    },
    {
        id: 6,
        src: "security-consultant.jpg",
        title: "security consultancy",
        content: "We are experts in the field of developing preventative and emergency procedures to ensure organization-wide continuity of operations."
    },
    {
        id: 7,
        src: "fire-safety.jpg",
        title: "fire system services",
        content: "We ensure fire safety with expert services, protecting lives and property from potential hazards."
    }
];


export const testimonial = [
    {
        id: 1,
        review: "I am pleased with the performance of the Western Safety Patrol staff assigned to our facilities.  The management responds quickly to questions and needs.  I would definitely recommend WSP to others.",
        src: "ellipse-4.svg",
        name: "Shiksha Sharma",
        designation: "manager",
        company: "Apollo Medicare"
    },
    {
        id: 2,
        review: "Things are going well. We genuinely appreciate the Western Safety Patrol partnership and your responsivess to the issues that arise at the shelter. If we extend to a different location, I will contact them for their service. Thanks again.",
        src: "amit_goyal.jpg",
        name: "Amit Goyal",
        designation: "Director",
        company: "Godhan Masale Foods pvt. ltd."
    },
    {
        id: 3,
        review: "Good service provided by Western Safety Patrol, quick repsonse, effective night patrolling. We wish all progress.",
        src: "kamal_batra.jpg",
        name: "Kamal Batra",
        designation: "Manager",
        company: "Natraj Stationary Products Pvt. Ltd."
    },
    {
        id: 4,
        review: "Good service provided by Western Safety Patrol, quick repsonse, effective night patrolling. We wish all progress.",
        src: "mohit-suri.jpg",
        name: "Kamal Batra",
        designation: "Manager",
        company: "Natraj Stationary Products Pvt. Ltd."
    },
];
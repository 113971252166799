import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GlobalContext } from "../Context/GlobalContext";
import { Skeleton } from "@mui/material";
import Aos from "aos";
import 'aos/dist/aos.css';
import { BASE_URL } from "../../VariableHandler/handler";


export default function Client(){

    const { apiDataAvailable, clientData, loader } = useContext(GlobalContext);
    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };

    useEffect(() => {
        Aos.init();
    },[]);

    if(loader){
        return (
            <Container>
                
                <Skeleton variant="rectangular" height={40} sx={{margin: "84px 0 0 0"}}/>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={100} height={100} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rectangular" height={60} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rounded" height={60} sx={{margin: "1em 0"}}/>
            </Container>
        )
    }

    return (
        <Section>
            <Container fluid>
                <Heading className="text-center section-title">Our Client</Heading>
                {
                    clientData.length && 
                    <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                        {
                            clientData.length && 
                                clientData.map((card,index) => (        
                                    <ClientImage key={`client-${index}`}>
                                    <img src={`${BASE_URL}/uploads/client/${card.image}`} alt={card.title} />
                                    </ClientImage>
                                ))
                        }
                    </ReactOwlCarousel>
                }
            </Container>
        </Section>
    )

}

const Section = styled.section`
    padding: 3em 0;

    .owl-stage{
        display: flex;
        align-items: center;
    }
    .owl-stage .owl-item{
        display: flex;
        justify-content: center;
    }
`;

const ClientImage = styled.div`
    width: 200px;
    display: flex;
    align-items:center;
    filter: grayscale(1);
    
    &:hover{
        filter: grayscale(0);
    }
`;

const Heading = styled.h2`
    font-size: 36px;
    font-family: var(--heading);
    color: var(--main-color);
    font-weight: 700;
    text-transform: capitalize;
    margin: 50px 0;
`;
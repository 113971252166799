import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Searchbar from "../Elements/Searchbar";
import "../Style/career.css";
import JobCard from "../Sections/JobCard";


export default function Career(){
    const [search, setSearch] = useState('');

    console.log(search);
    return(
        <React.Fragment>
            <section className="py-5">
                <Container fluid>
                    <Helmet>
                        <title>Prosecure | Career</title>
                        <meta name="description" content="Western Safety Patrol provides customized security services with a focus on customers. Our team, including ex-police and military members, keeps you safe."/>
                    </Helmet>
                    <div className="bg-image" style={{backgroundImage: "URL('frontend/assets/image/dark-theme-background.jpg')"}}>
                        <div className="py-3 position-relative" style={{zIndex: "999"}}>
                            <Container fluid>
                                <h1 className="page-name">Career</h1>
                                <div className="breadcrumb">
                                    <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                                    <NavLink to={'career'} className="breadcrumb-item active">Career</NavLink>
                                </div>
                            </Container>
                        </div>                                                                         
                    </div>
                    <Searchbar searchProps={setSearch}/>
                    <JobCard filter={search}/>
                </Container>
            </section>
        </React.Fragment>
    )
}
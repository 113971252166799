import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalContext";
import { BASE_URL } from "../../VariableHandler/handler";
import { Skeleton } from "@mui/material";

export default function Gallery(){

    const { galleryData, loader, apiDataAvailable } = useContext(GlobalContext);

    if(loader){
        return <Skeleton variant="circular"></Skeleton>
    }

    return(
        <section className="py-5">
            <Container fluid>
                <Row>
                    {
                         
                            galleryData.map((data, index) => (
                              <Col md={6} lg={4}>
                                <NavLink key={`gallery-item-${index}`} to={`/serviceview/${data.slug}`}>
                                  <div className='grid-container'>
                                    <img src={`${BASE_URL}/uploads/service/${data.image}`} className={`grid-item grid-item-${index}`} alt="" />
                                    <p>{data.title}</p>
                                  </div>
                                </NavLink>
                              </Col>
                              ))
                    }
                </Row>
            </Container>
        </section>  
    )
}


